<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="110px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="供应商名称：" prop="bodyName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyName }}</p>
                <el-input size="small" v-else :disabled="true" class="my-input-btn" v-model="ruleForm.bodyName" placeholder="请选择供应商名称">
                  <el-button v-if="editType == 'add'" slot="append" @click="chooseType" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="对接人：" prop="jointMan">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.jointMan }}</p>
                <el-input clearable v-else v-model="ruleForm.jointMan" placeholder="请输入对接人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="对接人手机：" prop="jointPhoneNumber">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.jointPhoneNumber }}</p>
                <el-input clearable v-else v-model="ruleForm.jointPhoneNumber" placeholder="请输入对接人手机" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>
            <el-col :span="24">
              <el-form-item label="邮箱：" prop="email">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.email }}</p>
                <el-input clearable v-else v-model="ruleForm.email" placeholder="请输入邮箱" maxlength=30 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="地址：" prop="address">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.address }}</p>
                <el-input clearable v-else v-model="ruleForm.address" placeholder="请输入地址" maxlength=300 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.remark }}</p>
                <el-input clearable v-else v-model="ruleForm.remark" placeholder="请输入备注" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <condition ref="condition" @getcondition="getcondition" :tempType="tempType"></condition>
    <!-- <certificate ref="condition"></certificate> -->
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import condition from '@/views/common/condition.vue'
import certificate from '@/views/common/certificate.vue'
export default {
  name: "supplyEdit",
  components: {
    condition,
    certificate
  },
  props:{
    tempType: {
      type: String,
      default: '01'
    },
  },
  data() {
    return {   
      rules: {
        bodyName: [{ required: true,  trigger: "change", message: '请选择供应商名称'}],
        email: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.email(val)){
              callback(new Error("请输入正确的邮箱"))
            }else{
              callback()
            }
          }
        }],
        jointPhoneNumber: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号码"))
            }else{
              callback()
            }
          }
        }]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {}
    };
  },
  created() {
	},
  methods: {
    getcondition(data){
      this.ruleForm.supplierBodyId = data.bodyId || ''
      this.ruleForm.bodyName = data.bodyName || ''
      this.ruleForm.address = data.businessAddress || ''
    },
    chooseType(){
      this.$refs.condition.initData()
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = ruleForm
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    submitForm(formName) {
      let _this = this,
        editType = this.editType;
      let mesInfo = {
        add: "新增成功！",
        edit: "修改成功！"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'commonsuppliersave' : 'commonsupplierupdate',
            target = {
            ..._this.ruleForm
          }
          _this.loading = true
          request[ways](target, _this.tempType == '01' ? '' : 'product-of-input').then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              _this.closeModel()
              _this.$emit("getData", editType == 'add' ? true : false)
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>


